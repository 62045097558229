body {
    --color-background: #fafafa!important;
    --color-foreground: #1f2023!important;
}

body.dark {
    --color-background: #1f2023!important;
    --color-foreground: #efefef!important;
}

main {
    background-color: var(--color-background);
    color: var(--color-foreground);
}