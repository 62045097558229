.img-container {
    /*display: flex;*/
    /*align-items: center;*/
    border-radius: 20px;
    height: 35vh;
    width: 100%;
    object-fit: cover;
    object-position: top;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    /*height: 7rem;*/
    transition: width 3s;
}

.img-scrolled {
    width: 14.5vw;
    top: 10px;
    position: fixed;
    object-position: left top;
}
