.headbox {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.contentMenu {
  background-color: #e3e4e6;
  height: 40px;
  text-align: center;
  -webkit-transition: height 1s;
  /* Safari */
  transition: height 1s;
}
.contentMenu:hover {
  height: 100px;
}

body {
  --color-background: #fafafa;
  --color-foreground: #1f2023;
}
body.dark {
  --color-background: #1f2023;
  --color-foreground: #efefef;
}
